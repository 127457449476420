<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="20">
        <ul>
          <li>
            <p style=" text-align :left">
              Tous documents référencés par
              <span style="color:red"> *</span> sont obligatoires et doivent
              être importés.
            </p>
          </li>
          <li>
            <p style="text-align :left">
              Tous documents représentés avec l'état
              <a-icon
                type="check-circle"
                theme="twoTone"
                twoToneColor="#52c41a"
              />
              signifie qu'ils ont bien été réceptionnés.
            </p>
          </li>
          <li>
            <p style="text-align :left">
              Tous documents représentés avec l'état
              <a-icon
                type="close-circle"
                theme="twoTone"
                twoToneColor="#eb2f96"
              />
              signifie qu'ils n'ont pas encore été chargés.
            </p>
          </li>
        </ul>
      </a-col>
    </a-row>
    <a-row type="flex" justify="center">
      <a-col :span="20">
        <a-table
          bordered
          :dataSource="dataSource"
          :columns="columns"
          :rowKey="id"
        >
          <!-- Row numbering  -->
          <template slot="row_index" slot-scope="text, record, index">
            <p>{{ index + 1 }}</p>
          </template>
          <!-- Document Name  -->
          <template slot="document" slot-scope="text, record">
            {{ record.nom_fr }}
            <span class="red_color" v-if="record.is_obligatoire == 1">*</span>
          </template>

          <!-- Document Uploader 'Action'-->
          <template slot="action" slot-scope="text, record">
            <div v-if="showDocumentUploader()">
              <a-upload
                name="file"
                :data="getAdditioanlData(record)"
                :multiple="false"
                :showUploadList="false"
                :action="getUrl(record.id)"
                :headers="{ authorization: `Bearer ${authData}` }"
                @change="handleChange(record, $event)"
                :beforeUpload="beforeUpload"
              >
                <a-button>
                  <a-icon type="upload" />
                  {{ !record.file_name ? "Sélectionner" : "Modifier" }}
                </a-button>
              </a-upload>
              <br />
              <a-button
                v-if="record.file_name"
                style="margin-top:5px;color: #eb2f96;background-color: white;"
                @click="deleteDocument(record.id)"
              >
                <a-icon type="delete" />Supp.
              </a-button>
              <a-progress
                v-if="showMe(record)"
                :percent="percent"
                size="small"
              />
              <!-- TODO : To uncomment when the returned object is containing the oriinal file name -->
              <!-- <span class="ant-upload-list-item-name6" v-if="record.fileName">
                {{record}}
              </span> -->
            </div>
          </template>

          <!-- START Preview Document  -->
          <template slot="preview" slot-scope="text, record">
            <a
              v-if="record.file_name"
              :href="record.file_url"
              @click="previewDocument"
              >Voir</a
            >
          </template>
          <!-- END Preview Document  -->
          <!-- Etat Document  -->
          <template slot="etat" slot-scope="text, record">
            <a-icon
              v-if="!record.file_name"
              type="close-circle"
              theme="twoTone"
              twoToneColor="#eb2f96"
            />
            <a-icon
              v-else
              type="check-circle"
              theme="twoTone"
              twoToneColor="#52c41a"
            />
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import constants from "@/const/const";
// import CreateForm from "./PersonalForm";

const columns = [
  {
    title: "N°",

    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Document",
    scopedSlots: { customRender: "document" },
  },
  {
    title: "Action",
    scopedSlots: { customRender: "action" },
    width: 100,
  },
  {
    title: "Visualiser",
    scopedSlots: { customRender: "preview" },
  },
  {
    title: "Etat",
    scopedSlots: { customRender: "etat" },
  },
];

const ADHERENT_UPLOAD_URL = "/user/upload-documents";
const ADMIN_UPLOAD_URL = "/admin/user/upload-documents";
const PDF_FILE = "application/pdf";
const PNG_FILE = "image/jpeg";
const JPEG_FILE = "image/png";
export default {
  name: "DossierForm",
  created() {},
  mounted() {
    if (this.isAdmin) {
      this.url = this.base_url + ADMIN_UPLOAD_URL;
    } else {
      this.url = this.base_url + ADHERENT_UPLOAD_URL;
    }
  },
  // components: {
  //   CreateForm,
  // },
  data() {
    return {
      CONFIRME: 1,
      SOUMIE: 1,
      percent: 0,
      confirmDirty: false,
      autoCompleteResult: [],
      columns: columns,
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
      pdf_link: null,
      header: {},
      url: null,
      currrent_dossier_reuis: 0,
    };
  },
  props: ["dataSource", "adherant", "isAdmin", "authData"],
  computed: {
    ...mapState({
      base_url: (state) => state.auth.base_url,
    }),
  },
  methods: {
    moment,
    getUrl: function(id) {
      return this.url;
    },
    showDocumentUploader() {
      // No modifications is allowed if 'dossier soumie' and it's accepted
      return !(this.adherant.etat === this.ETAT_ADHERANT_ACCEPTER);
    },

    getAdditioanlData(record) {
      return {
        user_id: this.adherant.id,
        file_id: record.dossier_requi_id,
      };
    },
    closeModal() {
      this.visible = false;
    },

    showModal() {
      this.visible = true;
    },
    setPdfLink(link) {
      this.pdf_link = null;
      this.pdf_link = link;
    },
    handleCancel() {
      this.visible = false;
    },
    previewDocument(e) {
      e.preventDefault();
      window.open(`${e.target.getAttribute("href")}`, "_blank");
    },
    deleteDocument(id) {
      this.$emit("delete_dossier", id);
    },
    showMe(record) {
      return record.id === this.currrent_dossier_reuis;
    },
    handleChange(record, info) {
      this.currrent_dossier_reuis = record.id;
      if (info.file.status === "uploading") {
        this.percent = Number.parseInt(info.file.percent);
      }
      if (info.file.status === "done") {
        this.percent = 0;
        this.currrent_dossier_reuis = null;
        this.$_showSuccessMessage(
          `${info.file.name} ${constants.UPLOAD_SUCCESS_FICHIER}`
        );
        this.$emit("refresh_list_dossier");
      } else if (info.file.status === "error") {
        this.currrent_dossier_reuis = null;

        this.$_throwAnError(`${constants.UPLOAD_FAIL}`);
      }
    },
    beforeUpload(file) {
      const isPdfOrImage =
        file.type === PDF_FILE ||
        file.type === JPEG_FILE ||
        file.type === PNG_FILE;
      if (!isPdfOrImage) {
        this.$_throwAnError(
          "Vous pouvez uniquement Joindre des fichiers pdf et les images!"
        );
      }
      return isPdfOrImage;
    },
  },
};
</script>
<style>
.red_color {
  color: red;
}
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

.decission_btn {
  text-decoration: underline;
}
.action_icons {
  margin: 5px;
  font-size: large;
}
.ant-upload-list-item-name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
