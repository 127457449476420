var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { type: "flex", justify: "center", align: "middle" } },
    [
      _c(
        "a-col",
        { attrs: { span: 24, md: 20 } },
        [
          _c("a-form-item", [
            _c(
              "h3",
              [
                _c("a-icon", {
                  staticStyle: { "margin-right": "5px" },
                  attrs: { type: "setting" }
                }),
                _vm._v("Règlement intérieur")
              ],
              1
            )
          ]),
          _c("a-form-item", [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.reglementInterieur) }
            })
          ]),
          _c(
            "a-form-item",
            [
              _c(
                "a-checkbox",
                {
                  attrs: { disabled: _vm.isDisabled, checked: _vm.isChecked },
                  on: { change: _vm.handleChange }
                },
                [
                  _vm._v(
                    "J'ai lu et j'accepte les conditions ci-dessus\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }