var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg" },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 20 } },
            [
              _c(
                "a-steps",
                {
                  staticStyle: { color: "red" },
                  attrs: { current: _vm.current }
                },
                [
                  _vm._l(_vm.steps, function(item) {
                    return _c("a-step", {
                      key: item.title,
                      staticStyle: { color: "red" },
                      attrs: { title: item.title },
                      on: {
                        click: function($event) {
                          return _vm.goTo(item.Index)
                        }
                      }
                    })
                  }),
                  !_vm.isAdmin
                    ? _c("a-step", {
                        key: "Confirmation",
                        staticStyle: { color: "red" },
                        attrs: { title: "Confirmation" },
                        on: {
                          click: function($event) {
                            return _vm.goTo(3)
                          }
                        }
                      })
                    : _vm._e()
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "steps-content" },
                [
                  _c(
                    "a-skeleton",
                    {
                      attrs: {
                        loading: !_vm.adherant && _vm.editMode,
                        active: true,
                        title: false,
                        paragraph: { rows: 20 }
                      }
                    },
                    [
                      _vm.current === _vm.PERSONAL_FORM
                        ? _c("PersonalForm", {
                            ref: "personalForm",
                            attrs: {
                              adherant: _vm.adherant,
                              wilayas: _vm.wilayas,
                              "is-admin": _vm.isAdmin,
                              auto: _vm.auto,
                              "photo-list": _vm.photoList,
                              "profile-pic-upload-url": _vm.profilePicUploadUrl
                            },
                            on: {
                              update_profile_pic: _vm.updateProfilePic,
                              clear_profile_pic: _vm.handleClearProfilePic
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.current === _vm.PROFESSIONAL_FORM
                    ? _c("ProfessionalForm", {
                        ref: "professionalForm",
                        attrs: {
                          adherant: _vm.adherant,
                          statuts: _vm.statuts,
                          wilayas: _vm.wilayas,
                          "wilayas-professional": _vm.wilayasProfessional,
                          specialites: _vm.specialites,
                          communes: _vm.communes,
                          diplomes: _vm.diplomes,
                          secteurs: _vm.secteurs,
                          "file-list": _vm.fileList,
                          "is-admin": _vm.isAdmin
                        }
                      })
                    : _vm._e(),
                  _vm.current === _vm.DOSSIER_FORM
                    ? _c("DossierForm", {
                        ref: "dossierForm",
                        attrs: {
                          adherant: _vm.adherant,
                          "auth-data": _vm.auto,
                          "is-admin": _vm.isAdmin,
                          "data-source": _vm.listDossier
                        },
                        on: {
                          refresh_list_dossier: _vm.refreshListDossier,
                          delete_dossier: function($event) {
                            return _vm.deleteDossier($event)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.current === _vm.ETAT_ACTIVITE_FORM
                    ? _c("EtatActiviteForm", {
                        key: _vm.newCotisationFormComponentKey,
                        ref: "etatActiviteForm",
                        attrs: {
                          user: _vm.adherant,
                          exercices: _vm.exercices,
                          "etat-activites": _vm.etatActivites,
                          "is-admin": _vm.isAdmin,
                          "etat-actvite-modal-visible":
                            _vm.etatActviteModalVisible,
                          processing: _vm.savingData,
                          saving: _vm.savingData
                        },
                        on: {
                          add: function($event) {
                            return _vm.$emit("addEtatActvite", $event)
                          },
                          update: function($event) {
                            return _vm.$emit("updateEtatActvite", $event)
                          },
                          delete: function($event) {
                            return _vm.$emit("deleteEtatActivite", $event)
                          },
                          delete_file: function($event) {
                            return _vm.$emit("deleteEtatActiviteFile", $event)
                          },
                          refresh_data: function($event) {
                            return _vm.$emit("getUser", $event)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.current === _vm.CONFIRMATION_FORM && !_vm.isAdmin
                    ? _c("ConfirmationForm", {
                        attrs: {
                          "is-checked": _vm.acceptTermsAndConditions,
                          "reglement-interieur": _vm.reglementInterieur
                        },
                        on: {
                          confirmation_changed: _vm.handleConfirmationChange
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "steps-action" },
                [
                  _c(
                    "a-spin",
                    { attrs: { spinning: !_vm.adherant && _vm.editMode } },
                    [
                      _vm.current > _vm.PERSONAL_FORM
                        ? _c(
                            "a-button",
                            {
                              staticClass: "precedent",
                              on: { click: _vm.prev }
                            },
                            [_vm._v("Précédent\n          ")]
                          )
                        : _vm._e(),
                      (_vm.current < _vm.steps.length && !_vm.isAdmin) ||
                      (_vm.current < _vm.ETAT_ACTIVITE_FORM && _vm.isAdmin)
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.savingData
                              },
                              on: {
                                click: function($event) {
                                  return _vm.next(_vm.steps[_vm.current].ref)
                                }
                              }
                            },
                            [_vm._v("Suivant\n          ")]
                          )
                        : _vm._e(),
                      (_vm.current === _vm.steps.length && !_vm.isAdmin) ||
                      (_vm.current === _vm.steps.length - 1 && _vm.isAdmin)
                        ? _c(
                            "span",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.processing
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleConfirmer(
                                        _vm.isAdmin
                                          ? _vm.steps[_vm.current].ref
                                          : ""
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Confirmer")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }