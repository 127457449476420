<template>
  <a-row type="flex" justify="center" align="middle">
    <a-col :span="24" :md="20">
      <a-form-item>
        <h3><a-icon type="setting" style="margin-right : 5px" />Règlement intérieur</h3>
      </a-form-item>
      <a-form-item>
        <span v-html="reglementInterieur">
        </span>
      </a-form-item>
      <a-form-item>
        <a-checkbox
          @change="handleChange"
          :disabled="isDisabled"
          :checked="isChecked"
          >J'ai lu et j'accepte les conditions ci-dessus
        </a-checkbox
        >
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 20 }
  }
};

export default {
  name: "ConfirmationForm",
  data() {
    return {
      formItemLayout
    };
  },
  props: ["isChecked", "isAdmin", "reglementInterieur"],
  computed: {
    isDisabled() {
      return this.isAdmin || this.isChecked;
    }
  },
  methods: {
    handleChange($event) {
      this.$emit("confirmation_changed", $event.target.checked);
    }
  }
};
</script>
