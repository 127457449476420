<template>
  <div>
    <EtatActiviteEditor
      ref="CreateForm"
      :visible="etatActviteModalVisible"
      @cancel="closeModal"
      @close="closeModal"
      @create="handleCreate"
      :key="newComponentKey"
      :processing="processing"
      :etatActivite="selectedEtatActivite"
      :dateInstallation="userDateInstallationText"
    />
    <a-row type="flex" justify="center"  class="table_head">
      <a-col>
        <a-button
          class="editable-add-btn"
          type="primary"
          @click="showModal"
          v-if="!processing"
          >Ajouter activité</a-button
        >
      </a-col>
      <a-col :span="22">
        <p><b>Veuillez saisir tout votre parcours d'activité professionnel (en incluant vos arrêts d'activité).</b></p>
      </a-col>
      <a-col :span="22" class="pd-l">
        <EtatActivitesList
          :etat-activites="etatActivites"
          :processing="processing"
          @refresh_data="() => $emit('refresh_data', id)"
          @delete_file="$emit('delete_file', $event)"
          @delete="$emit('delete', $event)"
          @edit="editEtatActivite($event, data)"
        />
      </a-col>
    </a-row>
  </div>
</template>
<script>
  import EtatActivitesList from "@/components/common/EtatActivite";
  import EtatActiviteEditor from "@/components/common/EtatActiviteEditor";
  import {mapActions} from "vuex";

  export default {
    name: "EtatActiviteForm",
    components: {
      EtatActivitesList,
      EtatActiviteEditor,
    },
    data() {
      return {
        selectedEtatActivite: null,
        newComponentKey: 0,
      };
    },
    props: {
      processing: {type: Boolean, default: () => false},
      saving: {type: Boolean, default: () => false},
      isAdmin: {type: Boolean, default: () => false},
      etatActviteModalVisible: {type: Boolean, default: () => false},
      user: {type: Object},
      etatActivites: {type: Array},
    },
    computed: {
      userDateInstallationText() {
        return this.user ? this.date(this.user.dt_autorisation) : "";
      },
    },
    methods: {
      getDemandeAttestationComponentKey() {
        this.newComponentKey = this.getRandomInt();
      },
      handleCreate() {
      const form = this.$refs.CreateForm.form;
      let that = this;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        let fields = form.getFieldsValue();
        let etatActivite = {
          ...fields,
          user_id: this.user.id,
        };
        this.formatFields(etatActivite, fields, ["date_changement"]);
        if (that.selectedEtatActivite) {
          this.$emit("update", {
            ...etatActivite,
            id: that.selectedEtatActivite.id,
          });

          return;
        }
        this.$emit("add", etatActivite);
      });
      },
      showModal() {
        this.getDemandeAttestationComponentKey();
        this.setModalVisible();
      },
      closeModal() {
        this.selectedEtatActivite = null;
        this.setModalInvisible();
      },
      setModalVisible() {
        this.etatActviteModalVisible = true;
      },
      setModalInvisible() {
        this.etatActviteModalVisible = false;
      },
      editEtatActivite(data) {
        this.selectedEtatActivite = data.etatActivite;
        this.showModal();
      },
      ...mapActions({
        deleteFile: "destroyAdminUserEtatActviteFile",
      }),
    },
};
</script>
<style scoped>
.table_head {
  margin-bottom: 5%;
}
.icons-list >>> .anticon {
  margin-right: 6px;
  font-size: 24px;
}
.pd-l {
  padding-left: 5%;
}
</style>
