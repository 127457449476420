var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c("a-col", { attrs: { span: 20 } }, [
            _c("ul", [
              _c("li", [
                _c("p", { staticStyle: { "text-align": "left" } }, [
                  _vm._v(
                    "\n            Tous documents référencés par\n            "
                  ),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v(" *")]),
                  _vm._v(
                    " sont obligatoires et doivent\n            être importés.\n          "
                  )
                ])
              ]),
              _c("li", [
                _c(
                  "p",
                  { staticStyle: { "text-align": "left" } },
                  [
                    _vm._v(
                      "\n            Tous documents représentés avec l'état\n            "
                    ),
                    _c("a-icon", {
                      attrs: {
                        type: "check-circle",
                        theme: "twoTone",
                        twoToneColor: "#52c41a"
                      }
                    }),
                    _vm._v(
                      "\n            signifie qu'ils ont bien été réceptionnés.\n          "
                    )
                  ],
                  1
                )
              ]),
              _c("li", [
                _c(
                  "p",
                  { staticStyle: { "text-align": "left" } },
                  [
                    _vm._v(
                      "\n            Tous documents représentés avec l'état\n            "
                    ),
                    _c("a-icon", {
                      attrs: {
                        type: "close-circle",
                        theme: "twoTone",
                        twoToneColor: "#eb2f96"
                      }
                    }),
                    _vm._v(
                      "\n            signifie qu'ils n'ont pas encore été chargés.\n          "
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 20 } },
            [
              _c("a-table", {
                attrs: {
                  bordered: "",
                  dataSource: _vm.dataSource,
                  columns: _vm.columns,
                  rowKey: _vm.id
                },
                scopedSlots: _vm._u([
                  {
                    key: "row_index",
                    fn: function(text, record, index) {
                      return [_c("p", [_vm._v(_vm._s(index + 1))])]
                    }
                  },
                  {
                    key: "document",
                    fn: function(text, record) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(record.nom_fr) +
                            "\n          "
                        ),
                        record.is_obligatoire == 1
                          ? _c("span", { staticClass: "red_color" }, [
                              _vm._v("*")
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "action",
                    fn: function(text, record) {
                      return [
                        _vm.showDocumentUploader()
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-upload",
                                  {
                                    attrs: {
                                      name: "file",
                                      data: _vm.getAdditioanlData(record),
                                      multiple: false,
                                      showUploadList: false,
                                      action: _vm.getUrl(record.id),
                                      headers: {
                                        authorization: "Bearer " + _vm.authData
                                      },
                                      beforeUpload: _vm.beforeUpload
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleChange(record, $event)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "upload" }
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              !record.file_name
                                                ? "Sélectionner"
                                                : "Modifier"
                                            ) +
                                            "\n              "
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("br"),
                                record.file_name
                                  ? _c(
                                      "a-button",
                                      {
                                        staticStyle: {
                                          "margin-top": "5px",
                                          color: "#eb2f96",
                                          "background-color": "white"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteDocument(record.id)
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "delete" }
                                        }),
                                        _vm._v("Supp.\n            ")
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showMe(record)
                                  ? _c("a-progress", {
                                      attrs: {
                                        percent: _vm.percent,
                                        size: "small"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "preview",
                    fn: function(text, record) {
                      return [
                        record.file_name
                          ? _c(
                              "a",
                              {
                                attrs: { href: record.file_url },
                                on: { click: _vm.previewDocument }
                              },
                              [_vm._v("Voir")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "etat",
                    fn: function(text, record) {
                      return [
                        !record.file_name
                          ? _c("a-icon", {
                              attrs: {
                                type: "close-circle",
                                theme: "twoTone",
                                twoToneColor: "#eb2f96"
                              }
                            })
                          : _c("a-icon", {
                              attrs: {
                                type: "check-circle",
                                theme: "twoTone",
                                twoToneColor: "#52c41a"
                              }
                            })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }